import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '@Services/core.service';
import { environment } from '@Env';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnchorPositionOptions, FloatOptions, FormatOptions, MobileOptions, NitroAd, OutstreamOptions, ReportPosition } from '../../types';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

const AdSizes = {
  banner: [['728', '90'], ['970', '90'], ['970', '250'], ['320', '50'], ['320', '100']],
  flatBanner: [['728', '90'], ['970', '90'], ['320', '50'], ['320', '100']],
  anchor: undefined,
  sticky: [ ['300', '250'], ['336', '280'], ['320', '480'], ['320', '100'], ['320', '50'] ],
  'in-content': [ ['300', '250'], ['336', '280'], ['320', '480'], ['320', '100'], ['320', '50'] ],
  sideRail: [['160', '600'], ['300', '600'], ['300', '250'], ['320', '50'], ['320', '100'], ['320', '480']],
  video: [['300', '250'], ['336', '280'], ['320', '480'], ['320', '100'], ['320', '50']],
};


@Component({
  selector: 'app-ad',
  standalone: true,
  imports: [
    NgClass,
    FaIconComponent
  ],
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnChanges, OnInit {

  @ViewChild('ad', { static: true }) adDiv: ElementRef<HTMLDivElement>;
  @Input() refreshTime = 30;
  @Input() renderVisibleOnly = false;
  @Input() enabled = true;
  @Input() icon = true;
  @Input() reportPosition: ReportPosition = ReportPosition.BottomRight;
  @Input() hideBreakpoint = 0;
  @Input() position: string;
  @Input() sizes: string[][];
  @Input({ required: true }) bannerId: string;
  @Input({ required: true }) size: keyof typeof AdSizes = 'banner';
  @Input() reloadOnNavigation = false;
  demo = !environment.production;
  adUnit: NitroAd;
  minHeight: number;
  minWidth: number;
  hidden = true;
  firstNavigate = true;

  constructor(private core: CoreService, private el: ElementRef, private router: Router) {
    // get max width of document
  }

  ngOnInit() {
    if (this.core.isServer) { return; }
    if (this.size !== 'video') {
      this.minHeight = this.filterSizes()?.reduce((acc, size) => parseInt(size[1], 10) > acc ? parseInt(size[1], 10) : acc, 0) ?? 0;
      this.minWidth = this.filterSizes()?.reduce((acc, size) => parseInt(size[0], 10) > acc ? parseInt(size[0], 10) : acc, 0) ?? 0;
    }
    if (this.reloadOnNavigation) {
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe(() => {
          if (this.adUnit && this.adUnit.onNavigate && typeof this.adUnit.onNavigate === 'function' && !this.firstNavigate) {
            console.log('navigating ad', this.adUnit.id);
            this.adUnit.onNavigate();
          }
          this.firstNavigate = false;
        });
    }

  }

  destroy() {
    if (this.adUnit && document.getElementById(this.adUnit.id)) {
      console.log('destroying ad', this.adUnit.id);
      document.getElementById(this.adUnit.id).remove();
    }
  }

  filterSizes() {
    return (this.sizes || AdSizes[this.size])?.filter(size => {
      return size[0] <= window.innerWidth;
    });
  }

  ngOnChanges() {
    if (this.core.isServer || this.core.adsDisabled) { return; }
    if (this.hideBreakpoint && window.innerWidth < this.hideBreakpoint) {
      this.hidden = true;
      return;
    }
    this.hidden = false;
    if (!this.adDiv.nativeElement.id) {
      this.adDiv.nativeElement.id = `${this.bannerId}`;
    }
    console.log('setting up ad', this.size);
    if (this.size === 'anchor') {
      this.setupAnchor();
    } else if (this.size === 'sideRail') {
      this.setupSiderail();
    } else if (this.size === 'video') {
      this.setupVideo();
    } else {
      this.setupClassic();
    }
  }

  setupAnchor() {
    this.adUnit = window.nitroAds.createAd('anchor', {
      refreshTime: 30,
      format: FormatOptions.AnchorV2,
      anchor: AnchorPositionOptions.Bottom,
      anchorPersistClose: false,
      railCloseColor: 'rgb(204,194,194)',
      demo: this.demo,
      sizes: this.filterSizes(),
      anchorBgColor: 'rgba(174, 67, 43, 0)',
      mediaQuery: '(min-width: 300px)',
      report: {
        enabled: true,
        icon: true,
        wording: 'Report Ad',
        position: ReportPosition.TopRight
      }
    });
  }

  setupSiderail() {
    this.adUnit = window.nitroAds.createAd('sideRail', {
      refreshTime: 30,
      format: FormatOptions.Rail,
      rail: this.position ?? 'left',
      railCloseColor: 'rgb(204,194,194)',
      railOffsetTop: 0,
      railOffsetBottom: 0,
      railCollisionWhitelist: [
        '*'
      ],
      demo: this.demo,
      sizes: this.filterSizes(),
      report: {
        enabled: true,
        icon: true,
        wording: 'Report Ad',
        position: ReportPosition.BottomRight
      }
    });

  }

  setupClassic() {
    this.adUnit = window.nitroAds.createAd(this.adDiv.nativeElement.id, {
      refreshTime: this.refreshTime,
      format: FormatOptions.Display,
      renderVisibleOnly: this.renderVisibleOnly,
      sizes: this.filterSizes(),
      demo: this.demo,
      report: {
        enabled: this.enabled,
        icon: this.icon,
        wording: 'Report Ad',
        position: this.reportPosition
      }
    });
  }

  async setupVideo() {
/*    this.adUnit = window.nitroAds.createAd('video', {
      format: FormatOptions.Floating,
      mediaQuery: '(min-width: 900px)',
      video: {
        float: FloatOptions.Always,
        hidePlaylist: true,
        mobile: MobileOptions.Compact,
        initialDelay: 5,
        interval: 30,
      },
      demo: this.demo,
      report: {
        enabled: true,
        icon: true,
        wording: 'Report Ad',
        position: ReportPosition.TopLeft
      }
    });*/
    if (document.getElementById('video-ad')) {
      return;
    }
    this.adUnit = await window.nitroAds.createAd('video-ad', {
      format: FormatOptions.Floating,
      demo: this.demo,
      mediaQuery: '(min-width: 900px)',
      outstream: OutstreamOptions.Auto,
      anchorPersistClose: true,
      renderVisibleOnly: false,
      report: {
        enabled: true,
        icon: true,
        wording: 'Report Ad',
        position: ReportPosition.TopLeft
      },
      video: {
        float: FloatOptions.Auto,
        hidePlaylist: false,
        initialDelay: 3,
        interval: 30,
        mobile: MobileOptions.Full,
        persistMinimizeTime: 0,
      }
    });
  }
}
